import $store from '@/store'

export default function () {
  const {
    organizationId,
    group,
    roleId
  } = $store.getters['auth/user'];

  const isCommittee = group === 'committee';
  const isOrganization = group === 'organization';
  const isAdmin = group === 'admin';
  const isOrgSetup = !!organizationId;

  /*  permissions: admins are the only role that limit menuitems */
  const isSuperAdmin = roleId === 1;
  const isCommitteeAdmin = roleId === 3;
  const isOrgAdmin = roleId === 5;
  const isUser = roleId === 6;
  const isViewer = roleId === 7;

  return {
    isCommittee,
    isOrganization,
    isAdmin,

    isSuperAdmin,
    isCommitteeAdmin,
    isOrgAdmin,
    isUser,
    isViewer,

    isOrgSetup
  }
}