<template lang="pug">
.sidebar-nav.bg-gray-50
  v-app-bar(
    dense
    fixed
    elevation="0"
    color="#1f2c52"
    height="67"
  )
    v-app-bar-nav-icon(
      color="white"
      @click="superMiniOpen = !superMiniOpen"
    )
    v-spacer
    AppBarIcons

  v-navigation-drawer(
    floating
    :mini-variant="mdAndDown && !smAndDown"
    mini-variant-width="80"
    :expand-on-hover="mdAndDown && !smAndDown"
    :permanent="!smAndDown"
    :bottom="smAndDown"
    v-model="superMiniOpen"

    app=''
    color="#1f2c52"
  )
    v-list-item( v-if="!mdAndDown" )
      v-list-item-content.text-blue-100
        v-list-item-title
          img( class="w-40" alt="CampaignArsenal Logo" src="/campaign-arsenal-full-grayscale.png" )

    v-list-item(
      v-if="mdAndDown"
    )
      v-list-item-content
        .d-flex.justify-center
          img( class="w-10 max-w-10" alt="CampaignArsenal Logo" src="/campaign-arsenal-logo-grayscale.png" )

    v-divider

    .d-flex.flex-column.overflow-y-hidden.justify-between( style="height: calc(100vh - 68px);" )
      //- MENU ITEMS --------------------------------------------------------------------------
      v-list
        v-list-item(
          dense
          :active-class="menuItem.activeClass"
          :class="menuItem.class"
          :to="menuItem.to"
          :disabled="menuItem.disabled"
          v-for="(menuItem, idx) in menuItems"
          :key="idx"
        )
          v-list-item-icon
            template
              v-icon( color="white" ) {{ !menuItem.disabled ? menuItem.icon : 'mdi-lock-outline' }}
          v-list-item-content.text-blue-100
            .d-flex
              v-list-item-title.cursor-pointer {{ menuItem.title }}

      .d-flex.flex-column( v-if="adminMenuItems.length" )
        //- ADMIN MENU ITEMS --------------------------------------------------------------------------
        v-list
          v-list-item(
            dense
            :active-class="adminMenuItem.activeClass"
            :class="adminMenuItem.class"
            :to="adminMenuItem.to"
            :disabled="adminMenuItem.disabled"
            v-for="(adminMenuItem, idx) in adminMenuItems"
            :key="idx"
          )
            v-list-item-icon
              v-icon( color="white" ) {{ !adminMenuItem.disabled ? adminMenuItem.icon : 'mdi-lock-outline' }}
            v-list-item-content.text-blue-100
              v-list-item-title.cursor-pointer {{ adminMenuItem.title }}

      v-divider( v-if="adminMenuItems.length" )

      div
        v-divider( v-if="orgMenuItems.length > 0" )
        //- ORG MENU ITEMS --------------------------------------------------------------------------
        v-list
          v-list-item(
            dense
            :active-class="orgMenuItem.activeClass"
            :class="orgMenuItem.class"
            :to="orgMenuItem.to"
            :disabled="orgMenuItem.disabled"
            v-for="(orgMenuItem, idx) in orgMenuItems"
            :key="idx"
          )
            v-list-item-icon
              v-icon( color="white" ) {{ orgMenuItem.icon }}
            v-list-item-content.text-blue-100
              v-list-item-title.cursor-pointer {{ orgMenuItem.title }}

        v-divider
        v-list-item.absolute.bottom-0.ml-3( :class="'hidden lg:block'")
          v-list-item-content.ma-0.pa-0.text-blue-100
            v-list-item-title
              span.text-xs.opacity-60 {{ organization && organization.name }}
  v-main
    .pb-8.px-2.px-md-12.v-main-body
      router-view( :key="$route.fullPath" )
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import menuItemBuilder from '@/helpers/menuItemBuilder'
import AppBarIcons from '@/components/AppBarIcons'

export default {
  name: 'SidebarLayout',

  components: {
    AppBarIcons
  },

  setup(props, { root: { $store, $vuetify } }) {
    const { fullName, organization } = $store.getters['auth/user'];

    // media / wireframe -----------------------------------------
    const superMiniOpen = ref(false);
    const mdAndDown = computed(() => $vuetify.breakpoint.mdAndDown)
    const smAndDown = computed(() => $vuetify.breakpoint.smAndDown)

    // menuItems
    const { menuItems, orgMenuItems, adminMenuItems } = menuItemBuilder()

    return {
      fullName,
      menuItems,
      adminMenuItems,
      orgMenuItems,

      organization,
      superMiniOpen,
      mdAndDown,
      smAndDown
    }
  }
}
</script>

<style scoped>
.v-main-body {
  /* 60px from nav bar, 32 from pt-8 */
  margin-top: 92px
}
</style>