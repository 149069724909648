import uiContext from './uiContext';

export default function () {
  const menuItems = []
  const adminMenuItems = [];
  const orgMenuItems = [];
  const {
    isCommittee,
    isOrganization,
    isAdmin,
    isSuperAdmin,
    isCommitteeAdmin,
    isOrgAdmin,
    isOrgSetup
  } = uiContext()
  const defaultMenuItem = {
    class: 'hover:bg-blue-600 ma-2 rounded',
    activeClass: 'bg-blue-700'
  }
  const add = (item) => menuItems.push(Object.assign({}, defaultMenuItem, item))
  const adminAdd = (item) => adminMenuItems.push(Object.assign({}, defaultMenuItem, item))
  const common = [
    {
      title: 'Expenses',
      to: '/expenses',
      icon: 'mdi-currency-usd',
      disabled: !isOrgSetup
    },
    {
      title: 'Receipts',
      to: '/receipts',
      icon: 'mdi-hand-extended-outline',
      disabled: !isOrgSetup,
    }
    // {
    //   title: 'Reports',
    //   to: '/reports',
    //   icon: 'mdi-chart-bar-stacked',
    //   disabled: !isOrgSetup
    // }
  ].filter(c => Object.assign(c, defaultMenuItem))

  // all
  add({
    to: '/dashboard',
    title: 'Dashboard',
    icon: 'mdi-home'
  })

  if (isAdmin) {
    if (isSuperAdmin) {
      adminAdd({
        title: 'Admins',
        to: '/admins',
        icon: 'mdi-account-multiple',
      })

      adminAdd({
        title: 'Vendors',
        to: '/vendors',
        disabled: true,
        icon: 'mdi-handshake-outline',
        class: 'opacity-75 hover:bg-blue-600 ma-2 rounded',
      })
    }

    add({
      title: 'Committee Admins',
      to: '/committeeAdmins',
      icon: 'mdi-account-group',
    })

    add({
      title: 'Org Admins',
      to: '/organizationAdmins',
      icon: 'mdi-sitemap-outline',
    })
  }

  if (isCommittee) {
    if (isCommitteeAdmin) {
      add({
        title: 'Team',
        to: '/users',
        icon: 'mdi-account-group',
        disabled: !isOrgSetup
      })

      add({
        title: 'Candidate',
        to: '/candidates',
        icon: 'mdi-account-star',
        disabled: !isOrgSetup
      })
    }

    menuItems.push( ...common )
  }

  if (isOrganization) {
    if (isOrgAdmin) {
      add({
        title: 'Team',
        to: '/users',
        icon: 'mdi-account-group',
        disabled: !isOrgSetup
      })

      add({
        title: 'Org Types',
        to: '/organization-types',
        icon: 'mdi-domain',
        disabled: !isOrgSetup
      })
    }

    add({
      title: 'Candidates',
      to: '/candidates',
      icon: 'mdi-account-star',
      disabled: !isOrgSetup
    })

    add({
      title: 'Advocacy',
      to: '/advocacies',
      icon: 'mdi-comment-multiple',
      disabled: !isOrgSetup
    })

    menuItems.push( ...common )
  }

  return {
    add,
    menuItems,
    orgMenuItems,
    adminMenuItems
  }
}